import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import { Link } from 'gatsby'
import { getAssetUrl } from '../services/cdn'
//import { Container as BlockContainer, Product, Parallax } from '../components/Blocks'
import { Container as BlockContainer, Product } from '../components/Blocks'
import { HeroCarousel } from '../components/Sections'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const IndexPage = () => (
  <Layout>
    <SEO title="Partnering" keywords={[`gatsby`, `application`, `react`]} />
    <BlockContainer padding={4} className="mt-3">
      <Container fluid className="p-0">
        <Row>
          <Col md="12">
            <HeroCarousel
              items={[
                {
                  src: getAssetUrl('agriculture/Rice-Mill-6.jpg'),
                  altText: 'RICEMILL',
                  captionHeader: '',
                  caption: '',
                },
                {
                  src: getAssetUrl('agriculture/Contract-Growing-3.jpg'),
                  altText: 'CONTRACT GROWING',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/Layer-Farm-4.jpg'),
                  altText: 'LAYER FARM',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-4.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-2.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/KoopLikas-5.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={2}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Be part of SIDC's Growing Community</h1>
              <p>SIDC offers partnership to help its member to grow. The Cooperative gives them the opportunity to invest their capital to the cooperatives select businesses.</p>
              <b>Be a SIDC Partner!</b>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={5} center={false}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="text-center">Choose Partnerships or Programs</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>Feedmill</b></h2>
                <div className="text-justify">
                  <p>SIDC Agriculture offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#feedmill">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>Contract Growing</b></h2>
                <div className="text-justify">
                  <p>SIDC Franchise offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#contract-growing">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>Layer Farm</b></h2>
                <div className="text-justify">
                  <p>SIDC Investments offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#layer-farm">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>Ricemill</b></h2>
                <div className="text-justify">
                  <p>SIDC Agriculture offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#Ricemill">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>KOOPLIKAS</b></h2>
                <div className="text-justify">
                  <p>SIDC Franchise offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#kooplikas">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card style={{ minHeight: '300px'}}>
              <CardBody className="p-4">
                <h2><b>SIDC Vet Products</b></h2>
                <div className="text-justify">
                  <p>SIDC Investments offers several opportunities to develop your Business and watch your Money grow.</p>
                </div>
                <AnchorLink className="btn btn-primary" href="#rightvet">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <Product
      noMargin={true}
      id="feedmill"
      partnerId="partner-feedmill"
      title="SIDC Feedmill"
      partnershipTitle="Feedmill Programs and Partnerships"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Feeds Outleting"},
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Feeds Dealership"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-5.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-3.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-1.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-4.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-2.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Feedmill-Slider-6.jpg'),
          altText: 'FEEDMILL',
          header: '',
          caption: ''
        }
      ]}
      downloads={[
        { fileName: 'Bigboy Flyer (Front)', link: 'Bigboy_Flyer_front.jpg' },
        { fileName: 'Bigboy Flyer (Back)', link: 'Bigboy_Flyer_back.jpg' },
        { fileName: 'SIDC Feeds Feeding Guide (Cover)', link: 'Bigboy_Flyer_outside.jpg' },
        { fileName: 'SIDC Feeds Feeding Guide (Content)', link: 'Bigboy_Flyer_inside.jpg' },
        { header: "Forms to Download" },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' },
        // { fileName: 'SIDC Feeding Guidelines', link: '' }
      ]}
      inquireLink="/inquire"
      departmentTitle="Involved SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <div className="lead">
        <p>The Cooperative is open for partnering through franchising of SIDC Feeds outlets and Coopmarts as long as there’s no existing SIDC branches in the area offering the same products and services. </p>
        <p>Technical assistance, learnings and seminars are also provided to SIDC members as support to the partnership.</p>
      </div>
    </Product>
    <Product
      id="contract-growing"
      partnerId="partner-contract-growing"
      title="SIDC Contract Growing"
      partnershipTitle="Contract Growing Programs and Partnerships"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "Contract Growing Member"},
        { iconClass:"fa fa-pencil-square-o", name: "Hog Raiser Awardees"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/Contract-Growing-2.jpg'),
          altText: 'CONTRACT GROWING',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Contract-Growing-5.jpg'),
          altText: 'CONTRACT GROWING',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Contract-Growing-1.jpg'),
          altText: 'CONTRACT GROWING',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Contract-Growing-3.jpg'),
          altText: 'CONTRACT GROWING',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Contract-Growing-4.jpg'),
          altText: 'CONTRACT GROWING',
          header: '',
          caption: ''
        }
      ]}
      downloads={[
        // { fileName: 'Intent Letter for Contract Growing', link: '' },
        { header: 'Forms to Download' },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' },
        // { fileName: 'SIDC Contract Growing Guidelines', link: '' }
      ]}
      inquireLink="/inquire"
      departmentTitle="Partnering SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3 className="">Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>The Cooperative is looking for members who wants to raise hogs and cattle for SIDC.</p>
        <p>The Cooperative will provide all necessary inputs like piglets, feeds, medications and technical assistance while also ensuring the market for their livestock.</p>
      </div>
    </Product>
    <Product
      id="layer-farm"
      partnerId="partner-layer-farm"
      title="SIDC Layer Farm"
      partnershipTitle="Layer Farm Programs and Partnership"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "Wholesale Program"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/Layer-Farm-1.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Layer-Farm-3.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Layer-Farm-2.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Layer-Farm-4.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Layer-Farm-5.jpg'),
          altText: '',
          header: '',
          caption: ''
        }
      ]}
      downloads={[
        { header: 'Forms to Download' },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' }
      ]}
      inquireLink="/inquire"
      departmentTitle="Partnering SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3>Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>The Cooperative are open for members or institution who wants to sell eggs for business.</p>
        <p>The Cooperative will set an agreement for the volume and schedule of deliveries that will be agreed upon by both parties.</p>
      </div>
    </Product>
    <Product
      id="ricemill"
      partnerId="partner-ricemill"
      title="Ricemill"
      partnershipTitle="Layer Farm Programs and Partnership"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "Buy Back Scheme"},
        { iconClass:"fa fa-pencil-square-o", name: "Loans Program"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/Ricemill 1.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Ricemill 2.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/Ricemill 3.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        // {
        //   src: getAssetUrl('agriculture/Rice-Mill-5.jpg'),
        //   altText: '',
        //   header: '',
        //   caption: ''
        // },
        // {
        //   src: getAssetUrl('agriculture/Rice-Mill-4.jpg'),
        //   altText: '',
        //   header: '',
        //   caption: ''
        // },
      ]}
      downloads={[
        { header: 'Forms to Download' },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' }
      ]}
      inquireLink="/inquire"
      departmentTitle="Partnering SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3 className="">Become part of a growing Cooperative</h3>
      <div className="lead">
      <p>The Cooperative provides program for small farmers by providing capital through  agricultural loans (agri-inputs) and technical assistance. In return, the harvested palay will be purchased by the cooperative for the production of rice. </p>
      </div>
    </Product>
    <Product
      id="kooplikas"
      partnerId="partner-kooplikas"
      title="KOOPLIKAS"
      partnershipTitle="Koop Likas Programs and Partnership"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "Loans Program"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/KoopLikas-1.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/KoopLikas-2.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/KoopLikas-3.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/KoopLikas-5.jpg'),
          altText: '',
          header: '',
          caption: ''
        },
        {
          src: getAssetUrl('agriculture/KoopLikas-4.jpg'),
          altText: '',
          header: '',
          caption: ''
        }
      ]}
      downloads={[
        { fileName: 'KoopLikas Brochure (Cover)', link: 'KoopLikas_Brochure_2.jpg' },
        { fileName: 'KoopLikas Brochure (Content)', link: 'KoopLikas_Brochure_1.jpg' },
        { header: 'Forms to Download' },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' }
      ]}
      inquireLink="/inquire"
      departmentTitle="Partnering SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3 className="">Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>SIDC manufactures OCCP Certified Organic Fertilizer for rice, corn, ornaments and vegetables.</p>
      </div>
    </Product>
    <Product
      id="rightvet"
      partnerId="partner-rightvet"
      title="SIDC Vet Products"
      partnershipTitle="SIDC Vet Products Programs and Partnership"
      partnershipItems={[
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Feeds Outleting"},
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Feeds Dealership"}
      ]}
      carouselItems={[
        {
          src: getAssetUrl('agriculture/SIDCVet.png'),
          altText: '',
          header: '',
          caption: ''
        }
      ]}
      downloads={[
        { fileName: 'RightVet Flyer', link: 'RightVet_2019_back.jpg' },
        { header: 'Forms to Download' },
        { fileName: 'Membership Application Form', link: 'membership_form_2018.pdf' },
      ]}
      inquireLink="/inquire"
      departmentTitle="Partnering SIDC Departments"
      department1={{
        link: '/',
        src: 'agriculture/SIDC-KooPinoy-Partnering.png'
      }}
      department2={{
        link: '/',
        src: 'agriculture/SIDC-Paiwi.png'
      }}
      department3={{
        link: '/',
        src: 'agriculture/SIDC-Sales-Department.png'
      }}
    >
      <h3 className="">Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>SIDC offers quality and affordable Antibiotics and Vitamins for Hogs and Poultry. Members can avail these products at all Agri Vet Section of Coop Supermarts, Coop marts and SIDC Feeds Outlets.</p>
      </div>
    </Product>
  </Layout>
)

export default IndexPage
